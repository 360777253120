.main-menu {
    font: 15px Exo-SemiBold;
    overflow-x: auto;
    overflow-y: none;
    white-space: nowrap;
}

.main-menu a {
    color: white;
    background-color: rgb(0, 167, 167);
    text-align: center;
    display: inline-block;
    padding: 14px;
    margin: 0;
    border-left-color: rgb(0, 255, 255);
    border-left-style: solid;
}

.main-menu a.menu-active {
    background-color: rgb(0, 109, 109);
}

.main-menu a:hover {
    background-color: rgb(0, 75, 75);
    text-decoration: none;
}
