.play-list-container {
    width: 100%;
    overflow-x: auto;
    overflow-y: auto;
    max-height: 60vh;
}

.play-list-table {
    max-width: 100%;
    margin: 0;
}

.play-list-table > thead th {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: rgb(22, 22, 22);
}

.play-item > td {
    padding-top: 1em;
    padding-bottom: 1em;
}
