.calculation-result {
    font: 12px Exo-Medium;
    border: none;
    table-layout: fixed;
    width: 100%;
    border-bottom: 1px solid rgb(100, 100, 100);
    border-collapse: collapse;
}

.calculation-result caption {
    font: 15px Exo-Bold;
}

.calculation-result tbody tr th {
    max-width: 35%;
}

.calculation-result tbody tr td {
    width: 65%;
    text-align: left;
    padding-left: 5px;
    background-color: rgba(68, 68, 68, 0.75);
}

.calculation-result tbody tr.calc-data th {
    max-width: 20%;
}

.calculation-result tbody tr.calc-data td {
    text-align: center;
}

.calculation-result tbody tr td img {
    position: relative;
    width: 32px;
    height: 20px;
}
