table,
th,
td {
    margin: 1em auto;
    padding: 5px;
    background-color: rgb(34, 34, 34);
    border: none;
    border-collapse: collapse;
    border-bottom: 1px solid rgb(100, 100, 100);
}
