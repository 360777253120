@font-face {
    font-family: Exo-Bold;
    src: url(../fonts/Exo-Bold.ttf);
}

@font-face {
    font-family: Exo-Medium;
    src: url(../fonts/Exo-Medium.ttf);
}

@font-face {
    font-family: Exo-SemiBold;
    src: url(../fonts/Exo-SemiBold.ttf);
}

body {
    background-color: #333;
    font: 15px Exo-Medium;
    color: white;
}

footer {
    background-color: #222;
    text-align: center;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 15px;
    font: 11px Exo-Medium;
}

hr {
    clear: both;
}

.top-banner {
    font: 20px Exo-Bold;
    text-align: center;
    background-image: url(../icons/header.png);
    background-position: center center;
    padding: 10px;
    color: black;
}

a:link {
    text-decoration: none;
    color: white;
}

a:hover {
    text-decoration: underline;
}

a:visited {
    text-decoration: none;
    color: white;
}

.subtitle {
    text-align: center;
}

.prototype-disclaimer {
    text-align: center;
    font: 15px Exo-Bold;
    max-width: 1000px;
    margin: 0 auto;
}

.prototype-description {
    font: 15px Exo-Medium;
    max-width: 1000px;
    margin: 0 auto;
}

h3.description {
    text-align: center;
}

h3.error-message {
    text-align: center;
}

.description {
    padding-left: 5px;
    font: 14px Exo-Medium;
}
