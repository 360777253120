.nav-button {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    margin-bottom: 10px;
    text-align: center;
}

.page-nav {
    background-color: #222;
    font: 14px Exo-Medium;
    color: white;
    width: 35%;
}

.page-nav:hover {
    background-color: rgba(211, 211, 211, 0.75);
}

.page-count {
    width: 20%;
    text-align: center;
    font: 14px Exo-Medium;
    color: white;
    margin-left: 10px;
    margin-right: 10px;
    background-color: #333;
}

.scroll-to-top {
    position: fixed;
    bottom: 10px;
    right: 10px;
    font-size: 3rem;
    z-index: 1;
    cursor: pointer;
    color: rgb(0, 255, 255);
    opacity: 60%;
}
