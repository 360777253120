.beatmap-filter-container {
    max-width: 1000px;
    margin: 0 auto;
}

.beatmap-filter-title {
    text-align: center;
}

.beatmap-filter {
    font: 12px Exo-Medium;
    border: none;
    border-bottom: 1px solid rgb(100, 100, 100);
    border-collapse: collapse;
}

.beatmap-filter td {
    text-align: left;
}

.beatmap-filter td ul li {
    position: relative;
    right: 25px;
    margin-bottom: 5px;
}

.beatmap-filter td ul li strong {
    font: 13px Exo-Bold;
}

.beatmap-filter td ul li p {
    margin-top: 0px;
}
